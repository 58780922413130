import {
  Box, Button, Container, Heading, Table, TableContainer, Tbody, Td, Th, Thead, Tr, useColorMode
} from "@chakra-ui/react";
import { Link } from "gatsby";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import Heroblock from "../../components/Heroblock";
import IntroComponentGreenGray3col from "../../components/IntroComponent1";
import PortfolioTeaser from "../../components/PortfolioTeaser";
import TeamQuadLink from "../../components/TeamQuadLink";
import FadeInAnimation from "../../DefinitionComponents/Animation-Components/FadeInAnimation";
import MaskSlidInAnimation from "../../DefinitionComponents/Animation-Components/MaskSlideInAnimation";
import GImage from "../../DefinitionComponents/Utility/GImage";

const SarahMichel = () => {
  const { setColorMode } = useColorMode();

  useEffect(() => {
    setColorMode("light");
  });

  return (
    <>
      <Helmet>
        <title>tokentus | Sarah Michel</title>
        <meta name="description" content="With years of experience up their sleeves, Oliver Michel and his team are here to play a significant role as investors in the emerging blockchain market.
" />   <link rel="canonical" href="https://tokentus.com/team/sarah-michel" />
      </Helmet>


      <Heroblock
        bgColor="black"
        justifyContent={"end"}
        mediaOpacity={0.5}
        video={false}
        minH={"90vh"}
        maxH={{ lg: "90vh", md: "auto" }}
        mediaSrc={"Tokentus_LowRes_Sarah-Michel-7693.jpg"}
      >
        <Container variant="layoutContainer" py={12} p={{base:0,lg:'8'}}>
          <MaskSlidInAnimation threshold={1} duration={0.6}>
            <Heading
              fontSize={{ base: "5xl", lg: "6xl" }}
              maxW="100%"
              fontWeight="normal"
             
              letterSpacing="tighter"
              color="brand.white"
            >
              Sarah Michel
            </Heading>
          </MaskSlidInAnimation>
          <FadeInAnimation threshold={0.4} duration={1} delay={0.4}>
            <Heading
              fontSize={{ base: "xl", lg: "2xl" }}
              maxW={{ base: "100%", lg: "52%" }}
              fontWeight="normal"
            
              color="brand.green"
            >
             Chief Operating Officer
            </Heading>
          </FadeInAnimation>
          <FadeInAnimation
            threshold={0.4}
            delay={1}
            duration={0.7}
            initialX={0}
            initialY={10}
          >
            <Link target="_blank " href="https://www.linkedin.com/in/-sarah-michel/">
              <Button
                color="brand.green"
                bg="brand.darkgray"
                mt={2}
                fontWeight={"normal"}
                px={6}
                variant="solid"
                leftIcon={<GImage w="14px" h="12px" src="icon_linkedin.svg" />}
              >
                LinkedIn
              </Button>
            </Link>
          </FadeInAnimation>
        </Container>
      </Heroblock>
    

<Box w='100%' bg='brand.darkgray' py={16}>
  <Container
          variant="layoutContainer"
          mx="auto"
          maxW={{ base: "100%", md:"90%",lg: "90%" }}
          px={12}
          pb={24}
        >
          <FadeInAnimation
            duration={1}
            delay={0.25}
            initialY={20}
            threshold={0.4}
          >
            <TableContainer>
              <Table
                color="brand.medlightgray"
                variant="simple"
                size="md"
                maxW={{ base: "100%", lg: "100%" }}
              >
                <Thead>
                  <Tr>
                    <Th color="brand.green">CV</Th>

                    <Th color="brand.green"></Th>
                  </Tr>
                </Thead>

                <Tbody>
                  <Tr>
                    <Td>2013 - 2016 </Td>

                    <Td color="brand.green"> <small>
                    Studied Psychology and Management <br/>at International School of Management Frankfurt </small>
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>2017 - 2019</Td>

                    <Td color="brand.green"> <small>
                    Studied Management at <br/>WHU – Otto Beisheim School of Management </small>
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>2020 - 2021</Td>

                    <Td color="brand.green"> <small>
                    Trainee Processes and Organizations <br/>at DZ Bank AG </small>
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>since 2021 </Td>

                    <Td color="brand.green"> <small>
                    COO of tokentus investment AG </small>
                    </Td>
                  </Tr>
                 
                </Tbody>
              </Table>
            </TableContainer>
          </FadeInAnimation>
        </Container>
      </Box>

      {/* NEWS */}

      <IntroComponentGreenGray3col
        headline="Experts and their vision."
        text1="The blockchain offers us a first-class opportunity that we definitely want to take advantage of."
        text2="With years of experience up their sleeves, Oliver Michel and his team are here to play a significant role as investors in the emerging blockchain market."
      />

      <TeamQuadLink />
      <PortfolioTeaser />
    </>
  );
};

export default SarahMichel;
